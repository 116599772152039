<script>
import {  common } from '@/mixins/common';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { GetApiActionWithAuthorization } from '../../../helpers/apiActions';

export default {
  name: 'BarcodeList',
  components: {
    Layout,
    PageHeader
  },
  props: {},
  data() {
    return {
        title: '',
        tab: '',
        pageFullyLoaded: true,
        onPageTabLoad: true,
        tableData: [],
        pagination: {
            page_size: 50,
            total: 1,
            page: 1
        },
        columns: [
            {
                key: "sellingPrice",
                value: 'Selling Price',
                sortable: false,
            },
            {
                key: "sellingGram",
                value: 'Selling Gram',
                sortable: false,
            },
            {
                key: "sellingPriceGram",
                value: 'Price / Gram',
                sortable: false,
            }
        ],
        items: [],
        tabs: [
            {
                value: 'Barcodes',
                link: 'barcode/index',
                label: 'barcode_index',
                icon: 'fas fa-list',
                active: false
            },
            {
                value: 'BIN',
                link: 'barcode/bins',
                label: 'barcode_bins',
                icon: 'fas fa-list',
                active: false
            }
        ],
        months: [
            { id: 1, name: "January" },
            { id: 2, name: "February" },
            { id: 3, name: "March" },
            { id: 4, name: "April" },
            { id: 5, name: "May" },
            { id: 6, name: "June" },
            { id: 7, name: "July" },
            { id: 8, name: "August" },
            { id: 9, name: "September" },
            { id: 10, name: "October" },
            { id: 11, name: "November" },
            { id: 12, name: "December" },
        ],
        years: [
            2021,
            2022,
            2023
        ],
        formFilter: {
            day: '',
            month: ((new Date()).getMonth()+1),
            year: new Date().getFullYear()
        },
        view: "year"
    }
  },
  mixins: [
    common
  ],
  methods: {
    getDateLink(date) {
        let _vm = this
        let params = { view: 'month', v: date}
        //
        if (_vm.view === 'month') {
            params.view = 'day'
            params.v = _vm.$route.query.v + '/' + date
        }
        return "?" + (new URLSearchParams(params)).toString()
    },
    updateCurrentPage(page) {
        this.loadPageData({ page })
        let url = window.location.href.split(/[?#]/)[0];    
        url += (url.indexOf('?') > -1) ? "&" : "?";
        url += "page=" + page
        window.history.replaceState({}, "Cybershop v2", url);
    },
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/' + tab.replaceAll('_', '/')
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    },
    loadPageData(params = { page: 1 }) {
        let _vm = this
        params.view = _vm.view
        params.viewValue = _vm.$route.query.v
        //let data = Object.assign(params, _vm.formFilter)
        return GetApiActionWithAuthorization("admin/orders/get-sold-items?" + (new URLSearchParams(params).toString())).then(res => {
            _vm.tableData = res.data
            _vm.pagination.page_size = res.page_size
            _vm.pagination.total = res.count
            if (res.data.length > 0) {
                _vm.pagination.page = params.page
            }
        });
    },
    findGram (description) {
      let weightInGrams = /(\d*(\.\d+))\s*(g)/.exec(description) || []
      return (weightInGrams.length > 1) ? weightInGrams[1] : 0 // 2 oz in gram
    },
    extractGram (description, defaultGram) {
        let gram = this.findGram(description)
        return gram > 0 ? gram : (defaultGram == 0 ? 2 : defaultGram)
    }
  },
  computed: {},
  mounted () {
    let page = this.$route.query.page || 1
    this.view = this.$route.query.view || 'year'
    this.loadPageData({ page })
    //
    if (this.view=="year"
        || !this.view
        || this.view == "month") {
        this.columns.unshift({
            key: "date",
            value: 'Date',
            sortable: false,
        })
    } else {
        this.columns.unshift({
            key: "name",
            value: 'Product',
            sortable: false,
        })
    }
  }
}
</script>
<template>
    <div class="barcode__content">
        <Layout>
            <PageHeader
                :title="title"
                :items="items"
                icon="bx bx-barcode"
                v-if="title.length > 0 && pageFullyLoaded"/>
            <div
                class="setting-page-container pt-4"
                v-if="pageFullyLoaded">
                <div class="">
                    <!--el-form
                      ref="form"
                      :model="formFilter"
                      :inline="true"
                      class="form-inline-custom mb-0"
                    >
                      <el-form-item>
                        <el-select
                          v-model="formFilter.month"
                          placeholder="Month"
                          @change="loadPageData({page:1})"
                          size="small"
                          clearable
                        >
                          <el-option
                            v-for="(option, index) in months"
                            :key="index"
                            :label="option.name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        /
                        <el-select
                          v-model="formFilter.day"
                          placeholder="Day"
                          @change="loadPageData({page:1})"
                          size="small"
                          clearable
                        >
                          <el-option
                            v-for="day in 31"
                            :key="day"
                            :label="day"
                            :value="day"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        /
                        <el-select
                          v-model="formFilter.year"
                          placeholder="Year"
                          @change="loadPageData({page:1})"
                          size="small"
                          clearable
                        >
                          <el-option
                            v-for="(option, index) in years"
                            :key="index"
                            :label="option"
                            :value="option"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          type="danger"
                          icon="fas fa-undo"
                          size="small"
                          circle
                          plain
                          @click="clearFilterSelection"
                        ></el-button>
                      </el-form-item>
                    </el-form-->
                  </div>
                <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="10"
                    class="table b-table"
                    >
                        <thead
                        role="rowgroup"
                        class=""
                        style="background-color: #74788d; color: white"
                        >
                        <tr role="row" class="">
                            <th
                            role="columnheader"
                            scope="col"
                            aria-colindex="1"
                            :class="[
                                column.center ? 'text-center': '',
                                'px-4'
                            ]"
                            v-for="column in columns"
                            :key="column.key"
                            >
                            <div>{{ column.value }}</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody role="rowgroup">
                        <tr
                            role="row"
                            aria-rowindex="1"
                            class=""
                            v-for="(data) in tableData"
                            :key="data.id"
                        >
                            <td
                                aria-colindex="1"
                                role="cell"
                                style="font-size: 16px"
                                class="col-sm text-uppercase">
                                <a :href="getDateLink(data.date)">
                                    {{ data.title }}
                                </a>
                            </td>
                            <td
                                aria-colindex="1"
                                role="cell"
                                style="font-size: 16px"
                                class="col-sm">
                                ${{ data.totalPurchasedPrice }}
                            </td>
                            <td
                                aria-colindex="1"
                                role="cell"
                                style="font-size: 16px"
                                class="col-sm">
                                {{ data.weightInGram.toFixed(2) }}g
                            </td>
                            <td
                                aria-colindex="1"
                                role="cell"
                                style="font-size: 16px"
                                class="col-sm">
                                ${{ (data.totalPurchasedPrice / data.weightInGram).toFixed(2) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pagination.total"
                    :page-size="pagination.page_size"
                    @current-change="updateCurrentPage"
                    :current-page.sync="pagination.page"
                    v-if="tableData.length > 0">
                </el-pagination>
            </div>
        </Layout>
    </div>
</template>
<style>
    .col-sm {
        padding: 0.4em 0.75em !important;
        vertical-align: middle;
    }
</style>

 